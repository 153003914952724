
import Layout from '@/admin/layouts/Default.vue';
import { Component, Vue } from 'vue-property-decorator';
import ResponseHandlerModule from '@/admin/store/modules/responseHandler';

import ContentModule from '@/admin/store/content';
import InputEntity from '@/lib/formFactory/inputEntity';
import { Input } from '@/lib/formFactory/input.interface';

import Loading from '@/components/Loading.vue';
import TitleReturn from '@/components/TitleReturn.vue';
import Editor from '@/components/modules/Editor.vue';
import BtnDefault from '@/components/BtnDefault.vue';
import BtnSubmit from '@/components/buttons/BtnSubmit/BtnSubmit.vue';
import InputDefault from '@/components/input/InputMain.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import { editSettingAuction, getSettingAuction } from '@/admin/api/settingAuction';
import { getApiErrorMessage } from '@/lib/utils/Utils';
import { tabsNavInterface } from '@/interfaces/tabs';
import TableSlider from './Table.vue';

@Component({
    components: {
        Layout,
        Loading,
        TitleReturn,
        Editor,
        BtnDefault,
        BtnSubmit,
        TabsNav,
        InputDefault,
        TableSlider,
    },
})
export default class MainPageComponent extends Vue {
    editorCounter = 0;
    title = 'Главная страница';
    isLoading = true;
    isTableLoading = true;
    sliderChangeTime: Input | {} = {};
    messages = {
        errorGet: 'Не удалось загрузить Политику конфиденциальности',
        errorEdit: 'Не удалось обновить Политику конфиденциальности',
        successSave: 'Изменения сохранены',
    };
    table = {};

    get tabsNav(): tabsNavInterface {
        return ContentModule.tabsNav;
    }

    get currentTab(): string {
        return (ContentModule.tabsNav as tabsNavInterface).main_page.id;
    }

    async save(): Promise<void> {
        const value = isNaN(+(this.sliderChangeTime as Input).value)
            ? 0
            : String(+(this.sliderChangeTime as Input).value * 1000);
        try {
            const result = await editSettingAuction('Slider', 'sliderChangeTime', `?value=${value}`);

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }
    }

    reset(): void {
        this.get();
    }

    async get(): Promise<void> {
        this.isLoading = true;

        try {
            const result = await getSettingAuction('?section=Slider&name=sliderChangeTime');
            const value = typeof result[0].value === 'string' ? +result[0].value / 1000 : 0;
            (this.sliderChangeTime as Input).value = String(value);
        } catch (e) {
            ResponseHandlerModule.showNotify({ message: this.messages.errorGet, type: 'fail' });
        }

        this.isLoading = false;
    }

    updateSliderChangeTime(value: string): void {
        (this.sliderChangeTime as Input).value = value;
    }

    async created(): Promise<void> {
        const sliderChangeTimeEntity = new InputEntity('sliderChangeTime');
        sliderChangeTimeEntity.setTitle('Интервал слайдера, сек');
        this.sliderChangeTime = sliderChangeTimeEntity.entity[sliderChangeTimeEntity.entityName];

        this.get();
    }

    beforeDestroy(): void {
        this.isLoading = true;
        this.sliderChangeTime = {};
    }
}
