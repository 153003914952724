import { Module, getModule, Action } from 'vuex-module-decorators';
import PageBaseModule from '@/admin/store/page';
import store from '@/admin/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { TableApiInterface, TableInterface, TableTitle } from '@/lib/layouts/page/table.interface';
import { getSliderContentTable } from '@/admin/api/sliderContent';
import { formatDefaultDateTo, formatToRelativeUrl, truncateString } from '@/lib/utils/Utils';

const MODULE_NAME = 'sliderContent';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class SliderContentModule extends PageBaseModule {
    text: { [key: string]: string } = {
        0: 'Нет',
        1: 'Да',
    };
    constructor(module: SliderContentModule) {
        super(module);

        const page = new PageEntity();
        page.setDefaultSortField('name');
        page.setSortName('NewsSort');
    }

    @Action({ rawError: true })
    init() {
        this.getList();
    }

    @Action({ rawError: true })
    async getList() {
        const sort = await this.getSortForRequest();

        this.SET_IS_LOADING(true);

        const table = await getSliderContentTable(
            this.pageSettings.pageCurrent,
            sort,
            '',
            this.pageSettings.pageAmountItems,
        );

        this.SET_TABLE(await this.prepareTable(table));
        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    prepareTable(table: TableApiInterface): TableInterface {
        const hiddenColumns = Object.values(table.titles as TableTitle[])
            .filter((title) => title.visible === false)
            .map((title) => title.id);

        const rows: any = [];

        let index: string;
        let row: any;
        for ([index, row] of Object.entries(table.rows)) {
            const rowProcessed: any = {};
            for (const i of Object.keys(row)) {
                let cell: any = row[i];

                if (i === 'title') {
                    cell = truncateString(cell, 60);
                }

                if (i === 'link') {
                    cell = formatToRelativeUrl(cell);
                }

                if (i === 'timerEnd' && cell) {
                    cell = formatDefaultDateTo(cell, 'DD/MM/YYYY HH:mm');
                }

                if (i === 'showOnMain') {
                    cell = this.text[+cell];
                }

                if (i === 'image' && cell?.length) {
                    cell = `<img src=${cell} width="80" class="cp-input-radius-25" />`;
                }

                if (i === 'createdByUser') {
                    cell = `<a href="${window.location.origin}/users/user/${cell.id}/" class="cp-table__item-link">
                        <span class="cp-table-column__bold">${cell.fullName}</span>
                    </a>`;
                }

                rowProcessed[i] = cell;
            }
            rows[index] = rowProcessed;
        }

        return {
            titles: table.titles,
            rows: rows,
            sortFields: table.sortFields,
            pagination: table.pagination ? table.pagination : {},
            totalItems: table.totalItems,
            filters: table.filters,
            hiddenColumns: hiddenColumns,
        } as TableInterface;
    }
}
export default getModule(SliderContentModule);
