
import { Component, Vue } from 'vue-property-decorator';
import Loading from '@/components/Loading.vue';
import TableBlock from '@/components/table/Table.vue';
import SliderContent from '@/admin/store/content/slider';
import AddIcon from '@/components/icons/Add.vue';
import { PageInterface } from '@/lib/layouts/page/page.interface';

@Component({
    components: {
        Loading,
        TableBlock,
        AddIcon,
    },
})
export default class TableSlider extends Vue {
    get settings(): PageInterface {
        return SliderContent.pageSettings;
    }

    get isLoading(): boolean {
        return SliderContent.isLoading;
    }

    tableAction(params: { field: string; sort: string; action: string }): void {
        if (params.action === 'sort') {
            SliderContent.sort({ field: params.field, sort: params.sort });
        }
    }

    toAdd(): void {
        this.$router.push({ name: 'slider_content_card_create' });
    }

    mounted(): void {
        SliderContent.init();
    }

    beforeDestroy(): void {
        SliderContent.reset();
    }
}
